<form [formGroup]="filterForm">
  <ng-container *ngFor="let filter of filterList">

  <mat-radio-group *ngIf="filter.radioSelection" aria-label="Select an option" [formControlName]="filter.controlName">
    <label> {{filter.radioSelection.label}} </label>
    <div class="radio-button-group">
      <mat-radio-button
      class="radio-button"
      [name]="filter.controlName"
      [value]="true"
      (click)="toggleFilter(true, filterForm.get(filter.controlName))"
      >{{ filter.radioSelection.trueLabel }}</mat-radio-button>
      <mat-radio-button
      class="radio-button"
      [name]="filter.controlName"
      [value]="false"
      (click)="toggleFilter(false, filterForm.get(filter.controlName))"
    >{{ filter.radioSelection.falseLabel }}</mat-radio-button>
    </div>
  </mat-radio-group>

  <mat-form-field class="select-wrapper" *ngIf="filter.dropdownSelection">
    <mat-label>{{ filter.dropdownSelection.label }}</mat-label>
    <mat-select [formControlName]="filter.controlName">
      <mat-option *ngFor="let country of filter.dropdownSelection.options | async"
      [value]="country.id">
      {{ country.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  </ng-container>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="clearFilter()" mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="applyFilter()" mat-button mat-dialog-close cdkFocusInitial>Apply</button>
  </mat-dialog-actions>
</form>
