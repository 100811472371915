import { Injectable } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { ALLOWED_IMAGE_FORMATS, ALLOWED_VIDEO_EXTENSIONS, ALLOWED_VIDEO_FORMATS } from '../resources/allowed-file-formats';
import { FileResponse, FileUpload } from '../model/upload-files.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {

  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
  ) { }

  readUploadedFileAsURL: (file: File, imageSizeLimit?: number) => Promise<string> = (file: File, imageSizeLimit?: number) => {
    if (imageSizeLimit && file.size > imageSizeLimit) {
      this.toastr.error('Files must be smaller than 5MB');
      return Promise.reject();
    }
    if (ALLOWED_IMAGE_FORMATS.includes(file.type)) {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const result = reader.result?.toString();
          resolve(result ? result : '');
        };

        reader.onerror = () => {
          reader.abort();
          this.toastr.error('Error occurred while uploading an image!');
          reject();
        };
      });
    } else {
      this.toastr.error('Uploading this type of file is not allowed');
      return Promise.reject();
    }
  };

  checkMediaForUpload(entries: NgxFileDropEntry[]): Promise<{file: File, isVideo: boolean}>[] {
    return entries.map(({fileEntry}: NgxFileDropEntry) => {
      if (fileEntry.isFile && !fileEntry.isDirectory) {
        return new Promise((resolve, reject) => {
          (fileEntry as FileSystemFileEntry).file((file: File) => {
            const isVideo = this.checkIsVideoFile(file);
            resolve({file, isVideo});
          });
        });
      } else {
        this.toastr.error('Uploading directories is not allowed"');
        return Promise.reject();
      }
    });
  }

  checkIsVideoFile(file: File): boolean {
    let isVideo: boolean;

    if (file.type === '') {
      const extension = file.name.substring(file.name.lastIndexOf('.'));
      isVideo = ALLOWED_VIDEO_EXTENSIONS.includes(extension);
    } else {
      isVideo = ALLOWED_VIDEO_FORMATS.includes(file.type);
    }

    return isVideo;
  }

  uploadImage(entries: NgxFileDropEntry[]): Promise<string>[] {
    return entries.map(({fileEntry}: NgxFileDropEntry) => {
      if (fileEntry.isFile && !fileEntry.isDirectory) {
        return new Promise((resolve, reject) => {
          (fileEntry as FileSystemFileEntry).file((file: File) => {
            this.readUploadedFileAsURL(file)
              .then((image) => resolve(image))
              .catch(() => reject());
          });
        });
      } else {
        this.toastr.error('Uploading directories is not allowed');
        return Promise.reject();
      }
    });
  }

}
