import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DataTableService } from '../../services/data-table.service';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Pagination } from 'src/app/model/pagination.interface';
import { Company } from 'src/app/model/company.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { SearchConfig } from 'src/app/model/search.interface';

@Injectable()
export class TableDataService implements DataSource<any> {

  private dataSubject = new BehaviorSubject<Company[]>([]);
  private lengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public length$ = this.lengthSubject.asObservable();

  constructor(private dataTableService: DataTableService,
              private errorHandlingService: ErrorHandlingService) { }

  connect(): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(apiUrl: string, search: SearchConfig | string, sortDirection: string = 'asc', pageIndex: number = 1, pageSize: number = 10, isHiddenQuery: boolean = false): void {
    this.loadingSubject.next(true);
    this.dataTableService.getData(apiUrl, search, sortDirection, pageIndex, pageSize, isHiddenQuery).pipe(
      catchError((errorResponse: HttpErrorResponse) =>
      this.errorHandlingService.handleBackendError(errorResponse)),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe((data: Pagination<Company>) => {
      if (apiUrl.substring(apiUrl.lastIndexOf('/') + 1) === 'companies') {
        data.data.forEach(company => company.country = company.location?.country);
      }
      this.dataSubject.next(data.data);
      this.lengthSubject.next(data.total);
    });
  }
}
