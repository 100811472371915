<mat-card class="header">
  <button mat-raised-button
          color="primary"
          (click)="goToCreate()">{{createButtonText}}</button>
  <ng-content select=".skill-question-selector"></ng-content>
</mat-card>
<div class="table-wrapper">
  <app-data-table *ngIf="apiUrl"
                  [apiUrl]="apiUrl"
                  [columnHeader]="columnHeader"
                  sortDirection=""
                  [pageSize]="10"
                  [pageSizeOptions]="[10, 50, 100]"
                  [search]="search"
                  [filterButton]="filterButton"
                  (rowClicked)="rowClicked($event)"
                  [isHiddenQuery]="isHiddenQuery"></app-data-table>
</div>
