import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(): boolean {
    const userRole = this.userService.user?.roles ? this.userService.user.roles[0] : '';
    if (userRole === 'ROLE_ADMIN') {
      return true;
    }
    this.router.navigate(['/kpi-statistics']);
    return false;
  }
}
