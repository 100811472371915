<mat-card class="media-upload">
  <div class="media-upload">
    <div class="label-wrapper">
      <label>Upload image or video</label>
    </div>
    <div class="ngx-file-drop-wrapper">
      <ngx-file-drop dropZoneLabel="Drop files here"
                     (onFileDrop)="mediaUpload($event)"
                     [accept]="allowedVideoAndImageFormats.join(', ')"
                     dropZoneClassName="ngx-file-drop-custom__drop-zone"
                     contentClassName="{{
          isRecording
            ? 'ngx-file-drop-custom__content'
            : 'ngx-file-drop-custom__content recording'
        }}"
        [disabled]="isDragAndDropDisabled && uploadInProgressInVideoComponent">
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector">
          <div class="drop-zone-content">
            <span *ngIf="!isDragAndDropDisabled && !uploadInProgressInVideoComponent">
            <mat-icon class="cloud-icon" (click)="openFileSelector()">cloud_upload</mat-icon>
            </span>

            <img *ngIf="picture?.value"
                 [src]="picture.value"
                 class="preview-image"/>

            <video *ngIf="video?.value"
                   #videoPlayer
                   class="video-preview"
                   [poster]="videoPosterUrl"
                   preload="metadata"
                   controls>
              <source [src]="videoMp4Url" />
              <source [src]="videoWebmUrl" />
            </video>
            <app-video *ngIf="!isMediaUploaded || replacingFile"
                       #videoComponent
                       [hideCommands]="true"
                       (videoStreamingComplete)="videoReady($event)"
                       (videoUploadComplete)="videoReady($event)"
                       (videoRecordingCanceled)="cancelRecording()"
                       (videoUploadInProgress)="uploadInProgressInVideoComponent = !!$event"
                       class="video-component"></app-video>
            <div class="controls"
                 *ngIf="!isRecording && !uploadInProgressInVideoComponent">
              <span class="message" *ngIf="!isDragAndDropDisabled">
                Drag and drop your image/video or
              </span>
              <div class="controls-button-wrapper">
                <button type="button"
                        [disabled]="uploadInProgress"
                        mat-raised-button color="primary"
                        (click)="initializeRecorder()"
                        *ngIf="!isImageUploaded && !video?.value">
                  Record
                </button>
                <button type="button"
                        mat-raised-button color="primary"
                        (click)="openFileSelector()"
                        *ngIf="!isDragAndDropDisabled">
                  Upload file
                </button>
                <!-- <button type="button"
                        mat-raised-button color="primary"
                        (click)="useImageAndVideoLibrary()">
                  From library
                </button> -->
                <button type="button"
                        mat-raised-button color="primary"
                        (click)="fileUpload.click()"
                        *ngIf="picture?.value || video?.value">
                  Replace
                </button>
                <button type="button"
                        mat-raised-button color="primary"
                        (click)="imageReset()"
                        *ngIf="picture?.value">
                  Remove image
                </button>
                <button type="button"
                        mat-raised-button color="primary"
                        (click)="videoReset()"
                        *ngIf="video?.value">
                  Remove video
                </button>
                <button type="button"
                        mat-raised-button color="primary"
                        (click)="openMergeVideosModal()"
                        *ngIf="video?.value && videoRecorded && !isImageUploaded">
                  Merge videos
                </button>
                <input #fileUpload
                       type="file"
                       [accept]="allowedVideoAndImageFormats.join(', ')"
                       (change)="replaceFile($event)"
                       hidden/>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>
  </div>
</mat-card>


