import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class SalesUserGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
    const userRole = this.userService.user.roles[0];
    const isAdmin = userRole === 'ROLE_ADMIN';
    const isSalesUser = userRole === 'ROLE_ADMIN_SALES';
    const skillQuestionRoutes = ['global-questions', 'skill-question-categories','skill-question-subcategories'];
    const allowSalesUser =
      isSalesUser && state.url.includes('company') && !!this.userService.user.allowedToCreateLabels ||
      isSalesUser && skillQuestionRoutes.some((skillRoute) => state.url.includes(skillRoute)) && !!this.userService.user.allowedToManagePredefinedBusinessQuestions;

    if (isAdmin || allowSalesUser) {
      return true;
    }

    this.router.navigate(['/kpi-statistics']);
    return false;
  }
}



