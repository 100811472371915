<div class="chip-list-wrapper">
  <mat-chip-list #chipList>
    <ng-container *ngFor="let data of selectData">
      <mat-chip class="cardinal-colors" (click)="toggleSelection(data)">
        {{ language ? data.name[language] : data.name }}
        <mat-icon class="mat-chip-remove">cancel</mat-icon>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>

<mat-form-field class="full-width">
    <input matInput type="text"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    (input)="onInputChange($event)">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngIf="addSelectAll && multiselect">
      <div (click)="onSelectAll($event)">
        <mat-checkbox
          [checked]="isSelectAllChecked"
          (change)="selectAll()"
          (click)="$event.stopPropagation()">
          <b>
            Select All Companies
          </b>
        </mat-checkbox>
      </div>
    </mat-option>
    <mat-option *ngFor="let data of filteredData">
      <div (click)="optionClicked($event, data)">
        <mat-checkbox [checked]="data.selected"
          (change)="toggleSelection(data)"
          (click)="$event.stopPropagation()">
          {{ language ? data.name[language] : data.name }}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>
